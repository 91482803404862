<template>
  <div>
    <CardList :patientData="patientData" @clickCard="clickCard" />

    <!-- 상담내역들 -->
    <ConsultingDialog ref="refConsultingDialog" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import CardList from "./CardList.vue";
import ConsultingDialog from "../ConsultingDialog.vue";
const formatService = require("@/utils/format.js");

export default {
  components: {
    CardList,
    ConsultingDialog
  },
  props: {
    patientData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      title: "...",
      data: [],
    };
  },
  methods: {
    clickCard(item) {
      // console.log('clickCard item', item);
      this.$refs.refConsultingDialog.open(item);
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>
<style lang="scss">
.top {
  display: flex;
  margin-bottom: 10px;
}

.tableTitle {
  margin-right: auto;
  padding-top: 14px;
  font-weight: 700;
}

.btnPos {
  padding: 10px;

  button {
    margin-right: 5px !important;
  }
}
</style>