<template>
  <base-card>
    <v-card-text @click="$emit('clickCard')">
      <div class="d-flex justify-space-between align-center flex-wrap mb-4">
        <div class="d-flex align-center">
          <img class="mr-md rounded-circle mr-2" height="44" width="44" :src="avatar" />
          <div>
            <p class="ma-0">
              <a href="#" class="text--primary font-weight-medium mb-1 hover:info--text">
                {{ title }}
              </a>
            </p>
            <!-- <p class="text--disabled caption ma-0">
              {{ subTitle }}
            </p> -->
          </div>
        </div>
      </div>
      <v-divider />
      <div class="d-flex justify-space-between align-center flex-wrap mt-4">
        <div v-if="hospitalId > 0" class="d-flex justify-end">
          <!-- <v-btn class="ma-2" color="" small depressed @click="$emit('inquiryReply')">
            {{ footerBtn }}
            {{ hospitalId }}
          </v-btn>
          <v-btn class="ma-2" color="" small depressed @click="$emit('inquiryPrice')">
            {{ footerBtnThree }}
          </v-btn> -->
          <!-- <v-btn
            class="ma-2"
            color=""
            small
            depressed
            @click="$emit('bookingSetting')"
          >
            {{ footerBtnTwo }}
          </v-btn> -->
        </div>
      </div>
      <div>
        <p class="text--disabled caption ma-0 pa-0 ml-3">
          최근상담요청일 : {{ registrationDate }}
        </p>
      </div>
    </v-card-text>
  </base-card>
</template>
<script>
export default {
  props: {
    hospitalId: {
      type: Number,
      default: 0,
    },
    avatar: {
      type: String,
      default: require("@/assets/images/avatars/001-man.svg"),
    },
    title: {
      type: String,
      default: "Timothy Clarkson",
    },
    subTitle: {
      type: String,
      default: "Vue Developer",
    },
    registrationDate: {
      type: String,
      default: "",
    },
    footerBtn: {
      type: String,
      default: "Medical",
    },
    footerBtnTwo: {
      type: String,
      default: "Profile",
    },
    footerBtnThree: {
      type: String,
      default: "Memo",
    },
    buttonColorOne: {
      type: String,
      default: "",
    },
    buttonIconOne: {
      type: String,
      default: "",
    },
    buttonIconTwo: {
      type: String,
      default: "",
    },
    buttonColorTwo: {
      type: String,
      default: "",
    },
    buttonIconThree: {
      type: String,
      default: "mdi-google",
    },
    buttonColorThree: {
      type: String,
      default: "",
    },
    progressLinearColor: {
      type: String,
      default: "primary",
    },
  },
  data() {
    return {
      // defaultFooterBtn: this.$t('patient.detail.photoCared.button.medicalInfo'),
    };
  },
  methods: {
    // clickMedicalInfo() {
    //   this.$emit("medicalInfo");
    // },
    // clickProfile() {
    //   this.$emit("profile");
    // },
    // clickMemo() {
    //   this.$emit("memo");
    // },
  },
};
</script>
