<template>
  <v-row>
    <v-col>
      <v-card-text class="card-title ma-0">
        {{ $t("menu.consultingUser") }}
        <!-- 도움말 -->
        <HelpMsg :msgType="helpMsg" />
      </v-card-text>
    </v-col>
    <v-col cols="12" class="ma-0 pa-0">
      <SearchTopUIComp :placeholder="$t('common.component.searchTop.searchInputWord2')" :propsSearchWorld="reqData.searchVal" @changeText="changeText"
        @changeSearchWordComplete="changeSearchWordComplete" />
    </v-col>

    <!-- 병원리스트 -->
    <v-col cols="12" class="ma-0 pa-0">
      <div class="uiSelectBox">
        <SelectBoxClinic @updateSelect="updateSelectClinic" />
      </div>
    </v-col>

    <!-- 상담리스트 -->
    <v-col cols="12">
      <UserList :patientData="patientData" />
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SearchTopUIComp from "@/components/commonV2/ui/SearchTopUIComp.vue";
import SelectBoxClinic from "@/components/commonV2/ui/SelectBoxClinic.vue";
import UserList from "./userList/_UserList.vue";
import HelpMsg from "@/components/commonV2/helpMsg/HelpMsg.vue";
const formatService = require("@/utils/format.js");
const modelService = require("@/utils/dataModel");

export default {
  components: {
    SearchTopUIComp,
    SelectBoxClinic,
    UserList,
    HelpMsg,
  },
  data() {
    return {
      patientData: [],
      helpMsg: this.$t("helpMsg.clinic"),
      reqData: modelService.reqDataModel(),
      propsDate: [this.$moment().subtract(30, "year"), this.$moment()],
    };
  },
  mounted() {
    if (this.meInfo && this.meInfo.userType !== "super")
      this.reqData.hospital_id = this.meInfo.hospital_id;
    this.getData();
  },
  methods: {
    /**** 검색어 ***/
    changeSearchWordComplete(e) {
      this.reqData.searchVal = e;
      this.getData();
    },
    changeText(e) {
      this.reqData.searchVal = e;
    },
    /**** 클리닉 선택 ***/
    updateSelectClinic(e) {
      console.log('e', e);

      this.reqData.hospital_id = e;
      this.getData();
    },
    /**** 데이터읽기 ***/
    async getData() {
      this.patientData = [];
      const $h = this.$helper;
      this.reqData.fromDay = $h.changeDateToString(this.propsDate[0], false);
      this.reqData.toDay = $h.changeDateToString(this.propsDate[1], false);
      this.reqData.endpoint = "consultingUser";
      this.reqData.patientType = "provisional"; // 예정환자
      await this.getDataQuery(this.reqData).then((resP) => {
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: index + 1,
            isUserTable: true,
            name: item.userName,
          }));
        }
        this.patientData = res;
        // 사용자아이디로 구룹핑
        console.log("this.patientData", this.patientData);
        this.patientData = $h.groupBy(this.patientData, (element) => {
          return [element.user_id];
        });
        // 
        this.patientData.forEach(element => {
          // console.log('element', element);
          element.forEach(e => {
            let obj = e;
            let lastObj = element[element.length - 1];
            // console.log('lastObj', lastObj);
            element.user_id = obj.user_id;
            element.toHospital_id = obj.toHospital_id;
            element.cardRegistrationDate = $h.changeDateToStringForDp(lastObj.regDate, true);
            element.name = `${obj.name}(${$h.changeGenderShortForDp(obj.gender)}/${obj.user_id})`;
            element.cardImg = $h.makeUserAvatarImg(obj.userPhotoImg, $h.isPhotoChecker(obj.userPhotoImg), obj.gender)
          });

        });
        console.log("this.patientData", this.patientData);
      });
    },
    ...mapActions(["getDataQuery"]),
  },
  computed: {
    ...mapGetters(["meInfo"]),
  },
};
</script>
<style lang="">
</style>