<template>
  <div>
    <v-row>
      <v-col md="12">
        <template>
          <v-row>
            <v-col v-for="(item, index) in patientData" :key="index" cols="12" sm="6" md="6" lg="4">
              <Card :hospitalId="item.hospital_id" :avatar="item.cardImg" :title="item.name" :sub-title="item.cardSubTitle" :registrationDate="item.cardRegistrationDate"
                :progress-linear-color="item.progressLinearColor" @clickCard="clickCard(item)" />
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Card from "./Card.vue";
export default {
  components: { Card },
  props: {
    patientData: {
      default: () => [],
      type: Array,
    },
    columns: {
      default: () => [],
      type: Array,
    },
    customColumn: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      // items: [
      //   {
      //     img: require("@/assets/images/avatars/001-man.svg"),
      //     title: "Timothy clarkson",
      //     subTitle: "React Developer",
      //     progressLinearColor: "primary",
      //   },
      // ],
    };
  },
  computed: {},
  methods: {
    clickCard(item) {
      // console.log('clickCard item', this.patientData);
      this.$emit('clickCard', item)
    },
    // clickRow(item) {
    //   console.log("", item);
    //   this.$emit("clickRow", item);
    // },
    // productInfo(item) {
    //   console.log("", item);
    //   this.$emit("nameClick", item);
    // },
  },
};
</script>

<style lang="scss">
.photoBox {
  text-align: center;
}

.iconStyle {
  font-size: 60px !important;
  color: rgb(210, 210, 210) !important;
}

.regTag {
  color: darkgray;
}
</style>