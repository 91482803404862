<template>
  <v-dialog v-model="dialog" max-width="800px" scrollable>
    <v-card class="editorPadding">
      <Header class="header" @close="close" /> 

      <v-card-text>
        <!-- <img v-if="consultingData"
          class="mr-md rounded-circle mr-2"
          height="44"
          width="44"
          :src="consultingData.cardImg"
        />
        [{{ consultingData && consultingData.regDateLabel }}]환자 상담요청 정보 -->
      </v-card-text>

      <ConsultingHistory 
        ref="refConsultingHistory" 
        :userData="userData" 
        :consultingData="consultingData" 
        @saveComplete="inputComplete" />

      <v-card-actions class="d-flex flex-reverse align-center justify-end">
        <v-btn color="primary" x-large depressed block rounded @click="clickReplyAdd">
          답변저장
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "@/components/commonV2/Dialog/comp/Header.vue";
import ConsultingHistory from "./ConsultingHistory.vue";
const modelService = require("@/utils/dataModel.js");

export default {
  components: { Header, ConsultingHistory },
  data() {
    return {
      dialog: false,
      userData: null,
      consultingData: null,
      reqData: modelService.reqDataModel(),
    };
  },
  methods: {
    open(userData) {
      this.dialog = true;
      this.userData = userData;
      this.getData();
    },
    close() {
      this.dialog = false;
    },
    clickBack() {
      this.dialog = false;
      this.$emit("clickCancel");
    },
    getData() {
      this.reqData.endpoint = "consulting";
      this.reqData.user_id = this.userData.user_id;
      this.reqData.hospital_id = this.userData.toHospital_id;
      const $h = this.$helper;
      this.getDataQuery(this.reqData).then((resP) => {
        if (resP === undefined) return;
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            regDateLabel: $h.changeDateToStringForDp(item.regDate),
            cardImg: $h.makeUserAvatarImg(item.userPhotoImg, $h.isPhotoChecker(item.userPhotoImg), item.gender)
          }));
        }
        // console.log("res", res);
        this.consultingData = res;
        // 날짜로 구룹핑
        this.consultingData = $h.groupBy(this.consultingData, (element) => {
          return [element.regDateLabel];
        });

        this.consultingData.forEach(element => {
          element.forEach(e => {
            let obj = e;
            element.regDateLabel = obj.regDateLabel;
            element.national = obj.national;
            element.basicSurgery = obj.basicSurgery;
            element.basicProcedure = obj.basicProcedure;
            element.cnSurgery = obj.cnSurgery;
            element.cnProcedure = obj.cnProcedure;
            element.btSurgery = obj.btSurgery;
            element.btProcedure = obj.btProcedure;
          });
        });

        console.log("consultingData", this.consultingData);
        setTimeout(() => {
          this.$refs.refConsultingHistory._resetPos();
        }, 500);
      });
    },
    inputComplete() {
      // this.dialog = false;
      // this.step = 'history';
      this.getData();
    },
    clickReplyAdd() {
      this.$refs.refConsultingHistory.clickAdd();
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
  computed: {
    ...mapGetters(["isDev", "meInfo"]),
  },
}
</script>
<style lang="scss">
.header {
  z-index: 10000;
  background: white;
}
</style>