<template>
  <v-card-text>
    <div class="app-content">
      <!-- 상담내용 -->
      <vue-perfect-scrollbar ref="ps" :settings="{ suppressScrollX: true, wheelPropagation: false }" class="rtl-ps-none ps scroll" style="height: calc(100% - 260px)">
        <div class="app-body mt-12 px-4">
          <div class="hGap"></div>
          <div v-for="(consulting, index) in consultingData" :key="index">
            <!-- 날짜 -->
            <div class="d-flex align-center my-3">
              <v-divider></v-divider>
              <div class="px-3">{{ consulting.regDateLabel }}</div>
              <v-divider></v-divider>
            </div>

            <!-- 환자 상담내용 -->
            <div v-for="(data, index2) in consulting" :key="index2">
              <div>
                <div class="d-flex align-center mb-5" v-if="data.isAdmin === 'N'">
                  <v-avatar size="41" class="mr-4">
                    <img :src="data.cardImg" />
                  </v-avatar>
                  <div> 
                    <div>
                      <span class="quoBox" v-if="data.isQuo === 'Y'">견적요청</span>
                      <p class="ma-0 bg-primary white--text py-2 px-3 rounded">
                        {{ $helper.js_nl2br(data.contents) }}
                      </p>
                    </div>
                    <div class="dpTime">{{ $helper.changeDateToStringTimeDp(data.regDate) }}</div>
                    
                    <!-- 첨부된 이미지 처리 -->
                    <div class="d-flex align-center mb-5">
                      <p class="imgBox" v-for="(uploadFile, index3) in data.uploadFiles" :key="index3" @click="imageClick(uploadFile)">
                        <img :src="uploadFile.fileUrl" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
              <!-- 병원답변 -->
              <div class="adminReplyBox">
                <div class="d-flex flex-reverse align-center mb-5 justify-end adminReplyBoxInner" v-if="data.isAdmin === 'Y'">
                  <div class="talk">
                    <div class="ma-0 white black--text py-2 px-3 rounded">
                      {{ data.contents }}
                      <v-btn text class="ma-0" color="danger" @click="clickDelete(data)">
                        삭제
                      </v-btn>
                    </div>
                    <div class="dpTime">{{ $helper.changeDateToStringTimeDp(data.regDate) }}</div>
                  </div>
                  <v-avatar size="41" class="mr-4">
                    <img src="@/assets/images/faces/doctor_m.png" alt="" />
                  </v-avatar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vue-perfect-scrollbar>

      <!-- 입력정보 -->
      <div class="app-footer white">
        <v-textarea ref="refContents" label="병원답변" v-model="reqData.contents" autofocus outlined class="mt-6" maxlength="2000" counter />
        <div v-if="consultingData">
          “귀 병원의 K_iM 수수료율은 총 진료 비용에 대하여 
          <!-- <b>수술은 ({{ consultingData[0].basicSurgery }})%</b>, 
          <b>시술은 ({{ consultingData[0].basicProcedure }})%</b>  -->
          <b>수술은 ({{ $helper.defineChargeWidthNation(consultingData[0]).surgery }})%</b>, 
          <b>시술은 ({{ $helper.defineChargeWidthNation(consultingData[0]).procedure }})%</b> 
          입니다" 
        </div>
        <v-textarea ref="refContents" label="견적내용" v-model="reqData.quote" autofocus outlined class="mt-6" maxlength="2000" counter />
        <!-- <v-btn color="primary" x-large depressed block rounded @click="clickAdd">
          답변저장
        </v-btn> -->
      </div>

      <DialogImageViewer ref="refDialogImageViewer" />
    </div>
  </v-card-text>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import DialogImageViewer from "@/components/commonV2/Dialog/DialogImageViewer.vue";
const modelService = require("@/utils/dataModel.js");

export default {
  components: {
    VuePerfectScrollbar,
    DialogImageViewer
  },
  props: {
    userData: {
      type: [Object, Array]
    },
    consultingData: {
      type: [Object, Array]
    },
  },
  data: () => ({
    reqData: modelService.consultingModel(),
  }),
  methods: {
    _resetPos() {
      this.$refs.ps.$el.scrollTop = 999999900000;
    },
    imageClick(item) {
      /** 이미지 파일이면 보이게 처리 */
      if (!this.$helper.checkImageFile(item.fileUrl)) {
        window.open(item.fileUrl, "_blank")
        return;
      }
      this.$refs.refDialogImageViewer.Open(item.fileUrl);
    },
    /** 병원답변 삭제 */
    clickDelete(item) {
      let msg = "상담답변을 삭제하시겠습니까?";
      if (!confirm(msg)) return;

      // console.log('item', item);
      const DT = this.reqData;
      DT.inquiry_id = item.inquiry_id;
      DT.useYN = "N";
      console.log('DT', DT);
      this.saveAction(DT);
    },
    /** 병원답변 */
    clickAdd() {
      // 필수체크
      const $h = this.$helper;
      const DT = this.reqData;
      const RF = this.$refs;

      let msg = "답변을 입력해주세요.";
      if (DT.contents === "") {
        $h.showTostMessage("", msg, "info");
        RF.refContents.focus();
        return;
      }

      msg = "상담답변을 저장하시겠습니까?";
      if (!confirm(msg)) return;

      console.log('consultingData', this.consultingData);
      console.log('userData', this.userData);

      DT.inquiry_id = 0;
      DT.useYN = "Y";
      DT.user_id = this.userData.user_id;
      DT.hospital_id = this.userData.toHospital_id;
      DT.toHospital_id = this.userData.toHospital_id;
      DT.consulting_id = DT.user_id + "_" + DT.toHospital_id;

      console.log('DT', DT);
      this.saveAction(DT)
    },
    saveAction(DT) {
      this.saveDataQuery(DT).then((resP) => {
        if (resP === undefined) return;
        DT.contents = "";
        this.$emit("saveComplete");
      });
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  }
}
</script>
<style lang="scss">
.hGap {
  height: 20px;
}

.app-content {
  background: #f2f4f4;
  position: relative;
  // top: 60px;
  width: 100%;
  height: calc(100vh - 280px);

  .adminReplyBox {
    display: flex;
    justify-content: right;
    .adminReplyBoxInner {
      width: 80%;
      .talk {
        margin-right: 10px;
      }
    }
  }

  .imgBox {
    padding: 5px 0 !important;

    img {
      width: 30px !important;
    }
  }

  .dpTime {
    padding: 8px 0 0 5px;
    font-size: 12px;
    text-align: right;
  }

  .app-footer {
    position: absolute;
    bottom: 50;
    padding: 10px;
    width: 100%;
    height: 280px !important;
  }

  .quoBox {
    position: relative;
    right: 10px;
    bottom: -8px;
    display: inline-block;
    padding: 4px 10px;
    background: red;
  }
}
</style>
